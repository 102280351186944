import React, { Component } from 'react';
import logoviop from '../views/travelSearch/viacaoOuroPrata.png';
import logobrisa from '../views/travelSearch/logobrisa.png';
import logoguanabara from '../views/travelSearch/logoguanabara.png';
import logounesul from '../views/travelSearch/logounesul.png';
import logottl from '../views/travelSearch/logottl.png';

import './styles.css';

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.logo = this.logo.bind(this)
    }

    logo() {
        var logo = ""
        switch (this.props.logo) {
            case "563":
                logo = logoguanabara
                break;
            case "564":
                logo = logobrisa
                break;
            case "50800":
                logo = logoviop
                break;
            case "50801":
                logo = logounesul
                break;
            case "57301":
                logo = logottl
                break;
            default:
            // code block
        }
        return logo


    }
    render() {
        return (
            <div className="footer">
                <a href="http://newsgps.com.br/" target="_blank" className="link">
                    Powered by <b><span>News</span><span className="color-red">GPS</span></b>
                </a>
                {/* <span>|</span>
                <img src={this.logo()} alt="logo" className="logo-footer" />
                {this.props.sugestoes &&
                    <>
                        <a href={this.props.sugestoes} target="_blank" className="link-form-sugestoes">Envie-nos sua sugestão</a>
                    </>
                } */}
            </div>
        );
    }
}