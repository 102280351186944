import React from 'react';

import Routes from './routes'

import './styles.css';

// import withSplashScreen from './componenents/withSplashScreen';



function App() {
  return (
    <Routes/>
  );
}

// export default withSplashScreen(App);
export default App;
