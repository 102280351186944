import React, { Component, } from 'react';
import L, { map } from 'leaflet';
import { Map, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import { HubConnectionBuilder } from '@microsoft/signalr';
import AppBar from '../../appbar/index';
import Footer from '../../footer/index';

import service from '../../../services/serviceapi';
import config from '../../../config';
import * as Formatadores from '../../util/formatadores';

import 'font-awesome/css/font-awesome.min.css';

import './styles.css';

export const vehicleIcon = new L.Icon({
  iconUrl: '/assets/images/bus.png',
  iconRetinaUrl: '/assets/images/bus.png',
  iconAnchor: [25, 50],
  popupAnchor: [10, -44],
  iconSize: [50, 50],
});

export const meIcon = new L.Icon({
  iconUrl: '/assets/images/me.png',
  iconRetinaUrl: '/assets/images/me.png',
  iconAnchor: [5, 30],
  popupAnchor: [10, -44],
  iconSize: [32, 40],
});

export const endmarker = new L.Icon({
  iconUrl: '/assets/images/endmarker.png',
  iconRetinaUrl: '/assets/images/endmarker.png',
  iconAnchor: [12, 32],
  popupAnchor: [0, -32],
  iconSize: [32, 35],
});

export const startmarker = new L.Icon({
  iconUrl: '/assets/images/startmarker.png',
  iconRetinaUrl: '/assets/images/startmarker.png',
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  iconSize: [32, 32],
});

export const stopmarker = new L.Icon({
  iconUrl: '/assets/images/stopmarker.png',
  iconRetinaUrl: '/assets/images/stopmarker.png',
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  iconSize: [32, 32],
});

export const pastmarker = new L.Icon({
  iconUrl: '/assets/images/pastmarker.png',
  iconRetinaUrl: '/assets/images/pastmarker.png',
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  iconSize: [32, 32],
});

export const transparentmarker = new L.Icon({
  iconUrl: '/assets/images/transparentmarker.png',
  iconRetinaUrl: '/assets/images/transparentmarker.png',
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
  iconSize: [32, 32],
});


export default class Tracker extends Component {

  openPopup(marker) {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup()

      }, 500)
    }
  }

  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.copyFunc = this.copyFunc.bind(this);
    this.openSharing = this.openSharing.bind(this);
    this.state = {
      zoom: 10,
      vehiclePoint: { lat: -22.908333, lng: -43.2096 },
      departurePoint: { lat: -22.908333, lng: -43.2096 },
      unDepaturePoint: { lat: -22.910333, lng: -43.3096 },
      markers: [],
      lastMarkerPosition: { lat: -22.910333, lng: -43.3096 },
      lastMarkerText: [],
      connection: null,
      geoJson: null,
      service: JSON.parse(localStorage.getItem("@tracker-app/travel")),
      me: { lat: -22.908333, lng: -43.2096 },
      myDistanceFromPoint: 0,
      messageInfo: "",
      areaSombra: false,
      ultimoReport: "",
      loading: true,
      erroReport: null
    };

    this.geoJsonLayer = React.createRef();
  }



  goBack() {

    var backUrl = this.props.location.state.clientUrl
    if (!backUrl) { backUrl = "/" }

    if (this.state.connection !== null) {

      if (this.state.connection.connectionState === "Connected") {
        this.state.connection.stop();
      }
    }

    //this.props.history.push('/');
    window.open(backUrl, "_self");
  }

  getDistance(lat1, lon1, lat2, lon2) {
    let rad = (x) => {
      return x * Math.PI / 180;
    }

    let R = 6378.137;
    let dLat = rad(lat2 - lat1);
    let dLong = rad(lon2 - lon1);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(lat1)) * Math.cos(rad(lat2)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;

    return d.toFixed(1);
  }



  trackMe() {
    let getGeo = (pos) => {

      let lat = pos.coords.latitude,
        lng = pos.coords.longitude;


      let distancePoint = this.state.vehiclePoint;
      let distance = this.getDistance(lat, lng, distancePoint.lat, distancePoint.lng);


      this.setState({
        me: { lat: lat, lng: lng },
        myDistanceFromPoint: distance
      });
    };

    let errorHandler = (err) => {

      switch (err.code) {
        case 1:
          //Permissão negada pelo usuario
          console.log("Permissão negada pelo usuario.");
          break;

        case 2:
          //Não foi possível alcançar os satélites GPS
          console.log("Não foi possível alcançar os satélites GPS");
          break;

        case 3:
          //A requisição demorou demais para retornar
          console.log("A requisição demorou demais para retornar");
          break;

        case 0:
          //Ocorreu um erro desconhecido...
          console.log("Ocorreu um erro desconhecido...");
          break;
        default:
          console.log(err);
          break;
      }

    };

    let options = {
      enableHighAccuracy: true,
      timeout: 30000,
      maximumAge: 3000
    };


    return {
      watch: () => navigator.geolocation.watchPosition(getGeo, errorHandler, options),
    }


  }

  async getRoutes() {
    //this.props.match.params
    const { serviceNumber } = this.props.match.params;
    //var verificaServico
    // if(this.props.location.state.date != ""){
    //   verificaServico = this.props.location.state.date + "/" + this.props.location.state.serviceNumber + "/" + this.props.location.state.idEmpresa
    // }else{
    //   verificaServico = this.props.location.state.serviceNumber + "/" + this.props.location.state.idEmpresa
    // }
    const pegaServico = this.props.location.state.date + "/" + this.props.location.state.serviceNumber + "/" + this.props.location.state.idEmpresa
    //const endpoint = `api/v1/service/${serviceNumber}`;
    const endpoint = `api/v1/service/${pegaServico}`;

    const response = await service.get(endpoint);
    const r = JSON.parse(response.data.route);
    if (r == null) {
      this.setState({
        messageInfo: "Nos desculpe, parece que ainda não existem rotas planejadas para esta viagem",
      });

      return;
    }

    this.geoJsonLayer.current.leafletElement.clearLayers().addData(r);
    this.setState({
      geoJson: r,
      departurePoint: {
        lat: r.features[0].geometry.coordinates[0][1],
        lng: r.features[0].geometry.coordinates[0][0]
      },
      unDepaturePoint: {
        lat: r.features[r.features.length - 1].geometry.coordinates[r.features[r.features.length - 1].geometry.coordinates.length - 1][1],
        lng: r.features[r.features.length - 1].geometry.coordinates[r.features[r.features.length - 1].geometry.coordinates.length - 1][0]
      }

    });
    //addmarker pontos
    const p = response.data.pontos;
    if (p) {
      var marcadores = []
      for (var i = 0; i < p.length; i++) {
        var item = p[i]
        marcadores.push([item.latitude, item.longitude, item.nome, item.chegadaPrevista, item.distancia, item.chegadaReal])
      }
      this.addMarker(marcadores)
    }
  }

  
  async componentDidMount() {
    
    if ('geolocation' in navigator) {
      this.trackMe().watch();
    }

    await this.getRoutes();

    const conn = new HubConnectionBuilder()
      .withUrl(config.trackerUrl)
      .withAutomaticReconnect()
      .build();

    conn.logging = true;
    this.setState({
      connection: conn
    });
  }

  searchText(nameKey, myArray) {
    //debugger;
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i][0] === nameKey) {
        return ([myArray[i][1],
        myArray[i][2],
        myArray[i][3]]
        )
      }
    }
  }

  addMarker = (e) => {
    //debugger;
    let markersArr = e.map((item) => {
      return ([{ lat: item[0], lng: item[1] }, { nome: item[2] }, { chegada: item[3] }, { distancia: item[4] }, { chegadaReal: item[5] }])
    })
    this.setState({ markers: markersArr })
  }

  openSharing() {
    var element = document.getElementById("sharingParentContainer");
    element.classList.toggle("showsharing");
  }

  copyFunc(text) {
    navigator.clipboard.writeText(text.replace(/%26/g,"&"))
    alert("URL Copiada!")
  }

  componentDidUpdate() {

    if (this.state.connection !== null) {

      if (this.state.connection.connectionState !== "Connected") {
        this.state.connection
          .start()
          .then(result => {
            const { serviceNumber } = this.props.match.params;
            const idGrade = this.state.service.idGrade;
            const idVeiculo = this.state.service.idVeiculo;

            this.state.connection.invoke("AddToMonitoringGroup", serviceNumber, idGrade, idVeiculo)
              .catch(e => console.log('Erro to AddToMonitoringGroup: ', e));

            console.log('Connected!');


            this.state.connection.on('ReceiveLocationFromBus', locationNotifiedToCustomer => {

              var zera = true

              if (locationNotifiedToCustomer.pontos !== null) {

                console.log('reportou pontos!');

                if (zera === true) {
                  clearInterval(downloadTimer);
                  document.getElementById("progressBar").value = 60
                }

                var timeleft = 60;
                var downloadTimer = setInterval(function () {

                  //console.log("reportou" + zera + timeleft)
                  document.getElementById("progressBar").value = 60 - timeleft;

                  if (timeleft <= 0) {
                    clearInterval(downloadTimer);
                  }

                  timeleft--;
                  zera = false

                }, 500);

              }

              console.log(locationNotifiedToCustomer)
              if(locationNotifiedToCustomer.latitude === '0' && locationNotifiedToCustomer.longitude === '0'){
                this.setState({ erroReport: locationNotifiedToCustomer});
              }else{
                this.setState({ erroReport: null});
              };
              this.setState({ loading: false })

              if (locationNotifiedToCustomer.areaSombra) {
                console.log("área de sombra");
                this.setState({ areaSombra: true })
                //this.setState({ ultimoReport: Formatadores.formataDataHora(locationNotifiedToCustomer.dataHoraEvento, "data-hora") })
              }
              if (!locationNotifiedToCustomer.areaSombra) {
                this.setState({ areaSombra: false })
                //this.setState({ ultimoReport: "" })
              }
              if (locationNotifiedToCustomer.isDisconected) {
                console.log("Fim da viagem, avisar ao usuário e desconectar da aplicação");
              }

              this.setState({ ultimoReport: Formatadores.formataDataHora(locationNotifiedToCustomer.dataHoraEvento, "data-hora") })

              let _service = JSON.parse(localStorage.getItem("@tracker-app/travel"));

              _service.latitudeProximoPonto = locationNotifiedToCustomer.latitudeNextDeparturePoint;
              _service.longiitudeProximoPonto = locationNotifiedToCustomer.longitudeNextDeparturePoint;
              let distance = this.getDistance(this.state.me.lat, this.state.me.lng, locationNotifiedToCustomer.latitude, locationNotifiedToCustomer.longitude);

              this.setState({
                vehiclePoint: { lat: locationNotifiedToCustomer.latitude, lng: locationNotifiedToCustomer.longitude },
                service: _service,
                myDistanceFromPoint: distance
              });
              //debugger;

              if (locationNotifiedToCustomer.pontos) {
                //console.log(locationNotifiedToCustomer);
                var marcadores = []
                for (var i = 0; i < locationNotifiedToCustomer.pontos.length; i++) {

                  var item = locationNotifiedToCustomer.pontos[i]

                  if ((locationNotifiedToCustomer.pontos.length - 1) !== i) {
                    marcadores.push([item.latitude, item.longitude, item.nome, item.chegadaPrevista, item.distancia, item.chegadaReal])
                  }
                  //console.log(locationNotifiedToCustomer.pontos.length + " e " + i)
                  if ((locationNotifiedToCustomer.pontos.length - 1) === i) {
                    //console.log("ultimo ponto")
                    this.setState({ lastMarkerText: [item.nome, item.chegadaPrevista, item.distancia] })
                    this.setState({ lastMarkerPosition: { lat: item.latitude, lng: item.longitude } })

                  }
                }
                this.addMarker(marcadores)

              }
            });

          })
          .catch(e => console.log('Connection failed: ', e));
      }
    }

  }

  geoStyles() {
    return { color: '#009688', "weight": 6, };
  }

  render() {
    const vehicleLocation = [this.state.vehiclePoint.lat, this.state.vehiclePoint.lng];
    const unDepartureLocation = [this.state.unDepaturePoint.lat, this.state.unDepaturePoint.lng];
    const departureLocation = [this.state.departurePoint.lat, this.state.departurePoint.lng];
    const meLocation = [this.state.me.lat, this.state.me.lng];
    const lastMarkerPosition = this.state.lastMarkerPosition;
    const lastMarkerText = [this.state.lastMarkerText];
    const erroReport = this.state.erroReport;

    var urlValue = ""
    //const urlToShare = "https://trackerapphml.newsgps.com.br/" + this.props.location.state.mapSharing
    if (this.props.location.state.idEmpresa == "50800"){
      urlValue = "https://rastreamento.viacaoouroeprata.com.br/" + this.props.location.state.mapSharing
    }else{
      urlValue = "https://trackerapp.newsgps.com.br/" + this.props.location.state.mapSharing
    }
    const urlToShare = urlValue
    
    return (


      <>
        <progress value="0" max="60" id="progressBar" style={{ position: "absolute", width: "100%" }}></progress>
        <div className="loading" style={this.state.loading ? { display: "block" } : { display: "none" }}>
          <div className="loader"></div>
        </div>
        <div className="sharing-buttons" id="sharingParentContainer">
          <i className="fa fa-share-alt-square" onClick={() => { this.openSharing() }}></i>
          <div className="compartilha-mapa">
            <a href={"https://api.whatsapp.com/send/?text=" + urlToShare} className="btn btn-default" target="_blank">Whatsapp</a>
            <a href={"https://telegram.me/share/url?url=" + urlToShare} className="btn btn-default" target="_blank">Telegram</a>
            <a href={"mailto:?subject=Compartilhando meu mapa&body=" + urlToShare} className="btn btn-default" target="_blank">Email</a>
            <a className="btn btn-default" onClick={() => { this.copyFunc(urlToShare) }}>Copiar/Colar</a>
          </div>
        </div>
        <div className="area-sombra" style={this.state.areaSombra ? { display: "block" } : { display: "none" }}>
          <h1>Veículo fora da área de cobertura! Aguarde...</h1>
          <br />
          <h4>Último posicionamento reportado: {erroReport === null ? this.state.ultimoReport : "sem report"}</h4>
        </div>
        
        <AppBar
          goBack={this.goBack}
          service={this.state.service}
          userDistanceFromPoint={this.state.myDistanceFromPoint}
          messageInfo={this.state.messageInfo}
        />


        <Map
          //center={meLocation}
          center={erroReport === null ? vehicleLocation : this.state.departurePoint}
          zoom={this.state.zoom}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />

          <GeoJSON
            ref={this.geoJsonLayer}
            data={this.state.geoJson}
            style={this.geoStyles} />


          {this.state.loading === false && erroReport === null &&
            
            <>
            {console.log("posição", erroReport)}
              <Marker position={vehicleLocation} icon={vehicleIcon} ref={this.openPopup} class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" alt="" tabindex="0" style="margin-left: -12px; margin-top: -41px; width: 25px; height: 41px; transform: translate3d(1949px, -458px, 0px); z-index: -458; outline: none;">
                <Popup className="localiza-carro">
                  <b>Último posicionamento<br />{this.state.ultimoReport}</b>
                </Popup>
              </Marker>

            </>
          }

          {/* <Marker position={meLocation} class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" alt="" tabindex="0" style="margin-left: -12px; margin-top: -41px; width: 25px; height: 41px; transform: translate3d(1949px, -458px, 0px); z-index: -458; outline: none;"
            icon={meIcon}>
          </Marker> */}

          {this.state.messageInfo === "" && this.state.loading === false &&
            <>
              <Marker position={departureLocation} icon={startmarker}>
                <Popup>
                  <b>Embarque</b>
                </Popup>
              </Marker>

            </>
          }

          {this.state.messageInfo === "" && this.state.loading === false &&
            <>
              <Marker position={unDepartureLocation} icon={endmarker} class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" alt="" tabindex="0" style="margin-left: -12px; margin-top: -41px; width: 25px; height: 41px; transform: translate3d(1949px, -458px, 0px); z-index: -458; outline: none;"
              >
                <Popup>
                  <b>Desembarque</b>
                </Popup>
              </Marker>
            </>
          }

          {//this.state.markers.length != 0 && this.state.markersText.length != 0 &&
            this.state.markers.map((marker, idx) =>
              <Marker key={`marker-${idx}`} position={marker[0]} icon={marker[4].chegadaReal === null ? stopmarker : pastmarker}>
                <Popup>
                  <b>Ponto de Parada: </b>{marker[1].nome}<br />
                  <b>{!marker[4].chegadaReal ? "Tempo Chegada Estimado: " : "Chegada: "} </b>{!marker[4].chegadaReal ? marker[2].chegada : marker[4].chegadaReal} <br />
                  <b>{!marker[4].chegadaReal ? "Distância: " : ""}</b>{!marker[4].chegadaReal ? marker[3].distancia : ""}<br />
                  <b>{this.state.areaSombra ? "(Tempo estimado baseado na última posição menos o tempo fora da área de cobertura)" : ""}</b>
                </Popup>
              </Marker>
            )}

          {this.state.loading === false &&
            <>
              <Marker position={lastMarkerPosition} icon={transparentmarker} class="leaflet-marker-icon leaflet-zoom-animated leaflet-interactive" alt="" tabindex="0" style="margin-left: -12px; margin-top: -41px; width: 25px; height: 41px; transform: translate3d(1949px, -458px, 0px); z-index: -458; outline: none;">
                <Popup>
                  <b>Ponto de Parada: </b>{lastMarkerText[0][0]}<br />
                  <b>Tempo Chegada Estimado: </b>{lastMarkerText[0][1]} <br />
                  <b>Distâcia: </b>{lastMarkerText[0][2]}
                </Popup>
              </Marker>
            </>
          }
        </Map>

        <Footer logo={this.props.location.state.idEmpresa} sugestoes={this.props.location.state.clientForm} />

      </>

    );
  }
}