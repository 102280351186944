import React, { Component, useState } from 'react';
import './splash-screen.css';
import splashviop from './views/travelSearch/splash-viop.png';
import splashguanabara from './views/travelSearch/splash-guanabara.png';
import splashunesul from './views/travelSearch/splash-unesul.png';
import splashbrisa from './views/travelSearch/splash-brisa.png';
import splashttl from './views/travelSearch/splash-ttl.png';
import splashtryvia from './views/travelSearch/splash-tryvia.png';

export const WithSplashScreen = (props) => {
  const loading = props.loading
  const empresa = props.empresa.emp
  var splash = ""

  switch (empresa) {
    case "563":
        splash = splashguanabara
        break;
    case "564":
        splash = splashbrisa
        break;
    case "50800":
        splash = splashviop
        break;
    case "50801":
        splash = splashunesul
        break;
    case "57301":
        splash = splashttl
        break;    
    default:
      splash = splashtryvia
      break;
}
  function LoadingMessage() {
    return (
      <div className="splash-screen">
        <img src={splash} alt="splash" className="splash"/>
        <br/>
        <div className="loading-dot" style={loading}>.</div>
      </div>
    );
  }

  //setLoading(props.loading)

  
  // while checking user session, show "loading" message
  return LoadingMessage()
    //LoadingMessage();

    // otherwise, show the desired route
  //return ("aqui")

  
  //export default WithSplashScreen
}  