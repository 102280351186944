import React, { Component } from 'react';

import imagemap from './TravelSearchVIOP.png'
import logoviop from './viacaoOuroPrata.png';
import logobrisa from './logobrisa.png';
import logoguanabara from './logoguanabara.png';
import logounesul from './logounesul.png';
import logottl from './logottl.png';
import logotryvia from './logotryvia.png';
import service from '../../../services/serviceapi';
import Footer from '../../footer/index'
import { Redirect } from 'react-router-dom';
import { WithSplashScreen } from '../../withSplashScreen';
import * as Formatadores from '../../util/formatadores';

import './styles.css';

var hasQueryParams = window.location.href.indexOf('?') !== -1
var queryParams = new URLSearchParams(window.location.search)

var emp = ""
var data = ""
var serv = ""
var pass = ""
var fromUrl = ""
var formUrl = ""
var sharingUrl = ""
var logo = ""

if (hasQueryParams) {
    if (queryParams.get("emp") != null) emp = queryParams.get("emp")
    if (queryParams.get("data") != null) data = queryParams.get("data")
    if (queryParams.get("serv") != null) serv = queryParams.get("serv")
    if (queryParams.get("pass") != null) pass = queryParams.get("pass")
    if (queryParams.get("fromUrl") != null) fromUrl = queryParams.get("fromUrl")
    if (queryParams.get("formUrl") != null) formUrl = queryParams.get("formUrl")

    sharingUrl = `?data=${data}%26serv=${serv}%26pass=${pass}%26emp=${emp}`
} else {
    //GUANABARA
    //emp = "563"
    //BRISA
    //emp = "564"
    //VIOP
    //emp = "50800"
    //UNESUL
    //emp = "50801"
    emp = "Empresa"

}

//console.log(sharingUrl)

switch (emp) {
    case "563":
        logo = logoguanabara
        break;
    case "564":
        logo = logobrisa
        break;
    case "50800":
        logo = logoviop
        break;
    case "50801":
        logo = logounesul
        break;
    case "57301":
        logo = logottl
        break;        
    default:
        logo = logotryvia
        break;
}


class TravelSearch extends Component {

    state = {
        travel: {},
        errorMessageToEmpptyService: "",
        clientUrl: fromUrl,
        clientForm: formUrl,
        mapSharing: sharingUrl,
        idEmpresa: emp,
        serviceNumber: serv,
        ticketNumber: pass,
        date: data,
        redirect: null,
        loading: false
    }

    componentDidMount = () => {
        this.setState({ loading: true })
        if (hasQueryParams) {
            this.onValidateTicket()

        } else {
            setTimeout(() => {
                this.setState({ loading: false })
                document.getElementById('dateSearch').valueAsDate = new Date();
                this.setState({ date: this.formatDate(new Date()) });
            }, 1000);
        }
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    handleClientChange = (e) => {
        let errorMessageToEmpptyService = "";

        if (e.target.value === "Empresa") {
            errorMessageToEmpptyService = "Escolha uma empresa para o rastreamento";
            this.setState({ loading: false })
            this.setState({ errorMessageToEmpptyService });
            return;
        }
        else {

            this.setState({ errorMessageToEmpptyService });
        }
        console.log(e.target.value)
        this.setState({ idEmpresa: e.target.value });
    };

    handleChange = (e) => {
        let errorMessageToEmpptyService = "";

        if (isNaN(e.target.value)) {
            errorMessageToEmpptyService = "O número do serviço é composto somente por números";
            this.setState({ loading: false })
            this.setState({ errorMessageToEmpptyService });
            return;
        }
        else {

            this.setState({ errorMessageToEmpptyService });
        }

        this.setState({ serviceNumber: e.target.value });
    };

    handleTicketChange = (e) => {
        let errorMessageToEmpptyService = "";

        if (isNaN(e.target.value)) {
            errorMessageToEmpptyService = "O número do bilhete é composto somente por números";
            this.setState({ loading: false })
            this.setState({ errorMessageToEmpptyService });
            return;
        }
        else {

            this.setState({ errorMessageToEmpptyService });
        }

        this.setState({ ticketNumber: e.target.value });
    };

    handleDateChange = (e) => {
        let errorMessageToEmpptyService = "";

        if (e.target.value === "") {
            errorMessageToEmpptyService = "A Data não pode ser nula";
            this.setState({ loading: false })
            this.setState({ errorMessageToEmpptyService });
            return;
        }
        else {

            this.setState({ errorMessageToEmpptyService });
        }

        this.setState({ date: e.target.value });
    };


    isEmptyTicket = () => {
        return this.state.serviceNumber === "" && this.state.ticketNumber === "";
    };


    onValidateTicket = async () => {
        //debugger;
        let errorMessageToEmpptyService = "";
        if (!this.isEmptyTicket()) {
            this.setState({ loading: true })
            var endpoint = "";

            if (this.state.serviceNumber) {
                endpoint = `api/v1/service/validateservice/${this.state.date}/${this.state.serviceNumber}/${this.state.idEmpresa}`;
                //endpoint = `api/v1/service/validateservice/${this.state.date}/${this.state.serviceNumber}`;
            }
            else if (this.state.ticketNumber) {
                endpoint = `api/v1/service/validateticket/${this.state.ticketNumber}/${this.state.idEmpresa}`;
                //endpoint = `api/v1/service/validateticket/${this.state.date}/${this.state.ticketNumber}/${this.state.idEmpresa}`;
                //endpoint = `api/v1/service/validateticket/${this.state.date}/${this.state.ticketNumber}`;
            }
            const response = await service.get(endpoint).catch(e => {
                //debugger;
                errorMessageToEmpptyService = "Parece que estamos enfrentando problemas com a internet";
                this.setState({ loading: false })
                this.setState({ errorMessageToEmpptyService });
            });

            if (errorMessageToEmpptyService !== "") {
                return;
            }


            if (response.data.isValid) {
                let travel = response.data.data;
                let dataviagem = response.data.data.previsaoPartida.split("T");
                this.setState({ serviceNumber: response.data.data.servico });
                localStorage.setItem("@tracker-app/travel", JSON.stringify(travel));

                if (this.state.mapSharing == "") {
                    this.setState({ mapSharing: `?data=${Formatadores.formataDataHora(dataviagem, "atad")}%26serv=${this.state.serviceNumber}%26pass=${this.state.ticketNumber}%26emp=${this.state.idEmpresa}` })
                }

                this.setState({
                    loading: false,
                    services: travel,
                    //redirect: `/tracker/${this.state.serviceNumber}`
                    //redirect: "/tracker/"
                    redirect: { serviceNumber: this.state.serviceNumber, date: dataviagem, idEmpresa: this.state.idEmpresa, clientUrl: this.state.clientUrl, clientForm: this.state.clientForm, mapSharing: this.state.mapSharing }
                    //redirect: `{serviceNumber:'${this.state.serviceNumber}'}`
                });



            }
            else {
                errorMessageToEmpptyService = response.data.message;
                this.setState({ errorMessageToEmpptyService });
                this.setState({ loading: false })
                return;
            }

        }
        else {
            errorMessageToEmpptyService = "Por favor preencha os campos";
            this.setState({ loading: false })
            this.setState({ errorMessageToEmpptyService });
            return;
        }

        fromUrl = null
        formUrl = null
        sharingUrl = null
        emp = null
        data = null
        serv = null
        pass = null
        logo = null
    };

    backToClient = () => {
        fromUrl = null
        formUrl = null
        sharingUrl = null
        emp = null
        data = null
        serv = null
        pass = null
        logo = null

        //window.history.back();

        var backUrl = this.state.clientUrl
        if (!backUrl) { backUrl = "/" }
        //console.log(backUrl)
        window.open(backUrl, "_self");
    }
    render() {

        if (this.state.redirect) {
            return <Redirect to={{ pathname: `/tracker/${this.state.serviceNumber}`, state: this.state.redirect }} />
            //return <Redirect to={this.state.redirect} />
        }
        if (this.state.loading) {
            return <><WithSplashScreen empresa={{ emp }} /></>
        } else if (hasQueryParams && this.state.errorMessageToEmpptyService) {
            return (
                <div className="container search-form">
                    <div className="input-box">
                        <WithSplashScreen loading={{ "display": "none" }} empresa={{ emp }} />
                        <>
                            <span className="message-alert">
                                {this.state.errorMessageToEmpptyService}
                            </span>
                            <button className='ticket-button' onClick={() => { this.backToClient() }}>encontrar meu ônibus</button>
                        </>
                    </div>
                </div>
            )
        } else {
            return (

                <>

                    <header className="header search-form">
                        <img src={logo} alt="logo" className="logo" />
                    </header>
                    <div className="container search-form">

                        <div className="input-box">
                            {/* <h1 className="app-title">TRACKER APP</h1> */}

                            <>
                                <h3>Quer saber onde seu onibus está? </h3>
                                <br />
                                <select id="empresas" onChange={this.handleClientChange} className='ticket-input' style={{display: hasQueryParams ? "none" : "inline-block"}}>
                                    <option value="Empresa">Escolha uma empresa</option>
                                    {/* <option value="563">GUANABARA</option>
                                    <option value="564">BRISA</option> */}
                                    <option value="50800">VIOP</option>
                                    <option value="50801">UNESUL</option>
                                    <option value="57301">TTL</option>
                                </select>
                                <br />
                                <input id="dateSearch" type="date" onChange={this.handleDateChange} className='ticket-input' required />
                                <br />
                                <h3>e</h3>
                                <input onChange={this.handleChange} className='ticket-input' type='text' required placeholder="Digite o número do serviço" />
                                <br />
                                <h3>ou</h3>
                                <input onChange={this.handleTicketChange} className='ticket-input' type='text' required placeholder="Digite o número da sua passagem" />
                                <br />
                            </>

                            {
                                this.state.errorMessageToEmpptyService &&
                                <span className="message-alert">
                                    {this.state.errorMessageToEmpptyService}
                                </span>
                            }

                            <button className='ticket-button' onClick={this.onValidateTicket} >encontrar meu ônibus</button>
                        </div>
                    </div>
                    <Footer logo={this.state.idEmpresa} />
                </>

            )

        }



    }

};

// export default withSplashScreen(TravelSearch);
export default TravelSearch;
